<template>
  <div id="collectionCard" class="d-flex flex-row justify-content-between">
    <div class="card-scene-parent">
      <div class="card-scene" :ref="sceneIndex" :id="sceneIndex"></div>
      <div class="card-scene-buttons d-flex justify-content-end">
        <div class="icon-svg mr-2">
          <collectionHeart :currentModelID="parseModelID" />
        </div>
        <div class="icon-svg mr-2">
          <exploreTelescope :currentModelID="parseModelID" />
        </div>
      </div>
    </div>
    <div class="card-metric-panel d-flex flex-column ml-4 flex-grow-1">
      <div
        id="metric-inputs-container"
        class="d-flex align-items-center flex-wrap justify-content-between"
      >
        <div
          class="input-label d-flex align-items-center m-1"
          v-for="(item, index) in inputArray"
          :key="index"
        >
          <div class="sublabel">{{ item.label }}:</div>
          <div class="label ml-2">
            {{ item.value | getInputLabel(item.key, item.marks) }}
          </div>
        </div>
      </div>
      <div
        id="metric-outputs-container"
        class="d-flex flex-column align-items-stretch justify-content-between flex-grow-1"
      >
        <div
          class="metric-panel d-flex justify-content-between"
          v-for="metric in metricCard"
          :key="'card-' + metric.label"
        >
          <div class="metric-label text-left">
            <div class="label">{{ metric.label }}</div>
            <div class="units-text">
              {{ metric.prettyValue }} {{ metric.units }}
            </div>
          </div>
          <div class="star-parent d-flex align-items-start">
            <star
              v-for="(star, index) in metric.starRatingArray"
              :key="index"
              :typeOfStar="star"
              :aboveBenchmark="metric.benchmark"
              :width="20"
              :height="20"
              :starColor="null"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @vue-import heart child component
 */
import collectionHeart from '@/components/Utility/collection_heart.vue'
import exploreTelescope from '@/components/Utility/explore_telescope.vue'

import { mapGetters } from 'vuex'
// import helper functions
import { getStarRating } from '@/assets/js/helper.js'
// star template
import star from '@/components/Utility/star.vue'

const modelObject = {}

export default {
  name: 'collectionCard',
  props: {
    modelID: String
  },
  components: {
    star,
    collectionHeart,
    exploreTelescope
  },
  data () {
    return {
      inputArray: [],
      metricCard: {}
    }
  },
  filters: {
    getInputLabel (i, key, marks) {
      return marks[i].label
    }
  },
  mounted () {
    Object.assign(modelObject, this.modelData[this.modelID])

    for (const key in modelObject) {
      if (this.inputHeaders.includes(key)) {
        this.inputArray.push({
          label: this.inputObject[key].label,
          value: modelObject[key],
          key: key,
          marks: this.inputObject[key].marks
        })
      }
    }

    this.metricCard = getStarRating(modelObject, this.metricObject)
  },
  computed: {
    ...mapGetters({
      modelData: 'getModelData',
      inputHeaders: 'getInputHeaders',
      metricObject: 'getMetricObject',
      inputObject: 'getInputObject'
    }),
    sceneIndex () {
      return 'collection-card-index-' + this.modelID
    },
    parseModelID () {
      return parseInt(this.modelID)
    }
  }
}
</script>

<style lang="scss" >
$card-size: 250px;
$card-width: calc(#{$card-size + 100px});

#collectionCard {
  .card-scene-parent {
    border: 1px solid $white;
    border-radius: 6px;
  }
  .card-scene {
    height: $card-size;
    width: $card-width;
    min-width: $card-width;
    background: transparent;
  }
  .card-metric-panel {
    height: $card-size;
  }
  .card-scene-buttons {
    position: relative;
    bottom: $font-md;
    right: $font-md;
  }
  #metric-inputs-container {
    margin-bottom: 0.5rem;
    border-bottom: 1px solid $white;
  }
}
</style>
