<template>
  <div id="collection">
    <div class="canvas-spacer lg"></div>
    <div
      class="top-container d-flex text-left p-2 justify-content-between flex-row align-items-center"
    >
      <div>
        <div class="d-flex flex-row align-items-center">
          <h1 class="page-title">Collection</h1>
          <div class="model-counter">
            <span class="ml-2">[ {{ collectionIDs.length }} ]</span>
          </div>
        </div>
        <div class="description">{{ collectionMessage }}</div>
      </div>
      <div class="clear-selection-button"></div>
    </div>
    <div id="cardContainer" ref="card-container">
      <canvas ref="collectionCanvas" id="canvasParent"></canvas>
      <div id="collectionContent" class="container-fluid styled-scrollbar">
        <div class="spacer-md"></div>
        <div class="card-row row mt-4" v-for="i in rowCount" :key="i">
          <div
            class="col-6 col-lg-6 col-md-12 col-sm-12 pr-4 pl-4"
            v-for="(modelIndex, index) in itemCountInRow(i)"
            :key="index"
          >
            <collectionCard :modelID="modelIndex.toString()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import collectionCard from '@/components/collection/collection_card.vue'

// import helper functions
import { cleanScenes } from '@/assets/js/helper.js'

import { createScenes, renderScenes } from '@/assets/js/sceneHelpers.js'

const THREE = require('three')
const OrbitControls = require('three-orbit-controls')(THREE)

let renderer = null
let scenes = []
let contextOBJ = null

export default {
  name: 'collection',
  components: {
    collectionCard
  },
  data () {
    return {
      cardsPerRow: 2
    }
  },
  created () {
    this.subscribeToStore()
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    this.unsubscribeFromStore()
  },
  destroyed () {
    function empty (elem) {
      while (elem.lastChild) elem.removeChild(elem.lastChild)
    }
    empty(this.canvas)

    cleanScenes(scenes)

    renderer.domElement.addEventListener('dblclick', null, false)
    renderer.renderLists.dispose()
    renderer.clear()

    scenes = []

    this.content.removeEventListener('scroll', this.render)
    window.removeEventListener('resize', this.render)
  },
  computed: {
    ...mapGetters({
      collectionIDs: 'getCollectionIDs',
      collectionModels: 'getCollectionModels',
      contextObject: 'getContextObject'
    }),
    rowCount: function () {
      return Math.ceil(this.collectionIDs.length / this.cardsPerRow)
    },
    collectionMessage: function () {
      return this.collectionIDs.length === 0
        ? 'No models in collection'
        : 'view saved models'
    }
  },
  methods: {
    onResize () {
      let width = this.canvas.clientWidth

      let height = this.canvas.clientHeight

      if (this.canvas.width !== width || this.canvas.height !== height) {
        renderer.setSize(width, height, false)
      }
    },
    render () {
      this.onResize()

      let el = document.getElementById('cardContainer').scrollTop

      this.canvas.style.transform = `translateY(${el}px)`

      renderScenes(scenes, renderer)
    },
    init () {
      // set loading flag on
      this.$store.commit('setLoadingFlag', true)

      this.canvas = document.getElementById('canvasParent')

      this.canvas.classList.add('inactive')

      this.content = document.getElementById('collectionContent')

      renderer = new THREE.WebGLRenderer({
        canvas: this.canvas,
        antialias: true
      })

      renderer.setPixelRatio(window.devicePixelRatio)
      let models = this.collectionIDs.map(
        (d) => d.toString().split('_option')[0]
      )

      this.$store.dispatch('readMultipleModelsByID', {
        models: models,
        metric: false,
        metricName: '',
        setter: 'setCollectionModels'
      })

      contextOBJ = this.contextObject
    },
    itemCountInRow (index) {
      return this.collectionIDs.slice(
        (index - 1) * this.cardsPerRow,
        index * this.cardsPerRow
      )
    },
    attachedScenesToContainers () {
      scenes.forEach((scene) => {
        const ID = 'collection-card-index-' + scene.name
        let sc = scene.userData
        sc.element = document.getElementById(ID)

        sc.createScene.constructControls(OrbitControls, sc.element)

        // setup controls
        const controls = sc.createScene.controls
        controls.enablePan = false
        controls.target.set(0, sc.modelSettings.y, 0)
        controls.update()
        controls.addEventListener('change', this.render)
      })

      this.updateSceneListeneres()
    },
    updateSceneListeneres () {
      window.addEventListener('resize', this.render)

      this.content.addEventListener('scroll', this.render)

      this.$store.commit('setLoadingFlag', false)

      this.canvas.classList.remove('inactive')

      this.render()
    },
    unsubscribeFromStore () {
      this.unsubscribeToCollectionModels()
      this.unsubscribeFromUpdateParentSceneFlag()
    },
    subscribeToStore () {
      this.unsubscribeFromUpdateParentSceneFlag = this.$store.subscribe(
        (mutation, state) => {
          switch (mutation.type) {
            case 'setUpdateParentSceneFlag':
              // Filter scenes based on the IDS in the collection
              scenes = scenes.filter((d) => {
                return this.collectionIDs.includes(parseInt(d.name))
              })
              // wait for the DOM to re-render with the correct elements based on the filtering
              this.$nextTick((e) => {
                this.attachedScenesToContainers()
              })
          }
        }
      )
      this.unsubscribeToCollectionModels = this.$store.subscribe(
        (mutation, state) => {
          switch (mutation.type) {
            case 'setCollectionModels':
              scenes = createScenes(
                this.collectionModels.models,
                scenes,
                contextOBJ
              )

              /**
               * async promise to ensure the DOM has updated with elements to attach
               * scenes to.
               */
              this.$nextTick((e) => {
                this.attachedScenesToContainers()
              })

              break
          }
        }
      )
    }
  }
}
</script>

<style lang="scss">
$top-container-height: 75px;

#collection {
  position: relative;
  // top: $navbar-height;

  height: calc(100vh - #{$navbar-height});
  padding: 0 2rem;

  overflow: hidden;
}

#collectionContent {
  position: absolute;

  height: calc(100vh - #{$navbar-height} - #{$top-container-height});
  width: 98%;
  overflow-y: auto;
  overflow-x: hidden;

  z-index: 1;

  &.inactive {
    display: none;
  }
  .card-row {
    margin-bottom: 6rem;
  }
}
</style>
